import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import PageWrapper from "@components/pageWrapper"
import { VideoPlayer } from "@components/video"

import {
  ScrollToButton, LearnMoreButton,
} from "@components/buttons"

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

const IndexPage = () => {
  return (
    <PageLayout
      heroTitle="Victory Hands"
      heroSubTitle="Historic Post-Rock for the silent majority."
      heroBackgroundImage="/images/victory-hands-toast.jpg"
    >
      <SEO
        title="Victory Hands and the Richard Nixon White House tapes"
        ogDescription="Victory Hands are an Atlanta-based quartet of veteran musicians playing angular rhythms and anthemic melodies with lyrics taken straight from the tapes and speeches of Richard Milhous Nixon."
        image="https://victoryhands.com/images/victory-hands-toast.jpg"
        url="https://victoryhands.com"
      />
      <TextBlock
        textPadded
        textLeft={
          <div id="about">
            <p>
              Victory Hands are an Atlanta-based quartet (previously trio) of veteran musicians playing angular rhythms and anthemic melodies with lyrics taken straight from the tapes and speeches of Richard Milhous Nixon. The paranoia, stilted language and rah-rah aggression of Nixon and cohorts combines with intricate music to create an oddly compelling form of abstract punk-rock poetry.
            </p>
            <ScrollToButton
              id="top-brass-video"
              content="Watch Top Brass Video"
              style={{ marginBottom: "20px" }}
            />
          </div>
        }
        textRight={
          <div>
            <img src="/images/victory-hands-spill.jpg" alt="Victory Hands - the trio days" />
          </div>
        }
      />
      <PageWrapper color="charcole">
        <SingleColumnBlock
          centered
          text={
            <div>
              <h2>The Modern Era:<br />BRADEN - BRADLEE - BRUCKNER</h2>
              <p>BRADLEE and BRUCKNER releases are in process with estimated completion dates in 2023.</p>
            </div>
          }
        />
        <TextBlock
          textPadded
          textLeft={
            <div>
              <img src="/images/BRADEN.jpg" alt="BRADEN 7-inch by Victory Hands" />
              <p className="small">Illustrations by Bob Rob Medina.</p>
            </div>
          }
          textRight={
            <div>
              <h3>BRADEN 7"</h3>
              <p>
                This is the first release with a 4-member cabinet. Featuring "Everyone Loves A Parade" and "A Man Named Hope".
              </p>
              <ScrollToButton
                id="hope-live-video"
                content="Watch A Man Named Hope Live Video"
                style={{ marginBottom: "20px" }}
              />
              <h4>Download</h4>
              <p>
                Download in lossless or lossy archival format of your choice at <a href="https://victoryhands.bandcamp.com/" target="_blank">Bandcamp</a>.
              </p>
              <h4>Stream</h4>
              <p>
                <a href="https://open.spotify.com/album/1QV90siIw6OOxBp7jVFF4l" target="_blank">Spotify</a><br />
                <a href="https://music.apple.com/us/album/braden-single/1622698110" target="_blank">Apple Music</a><br />
                <a href="https://tidal.com/browse/album/227717606" target="_blank">Tidal</a><br />
                <a href="https://www.amazon.com/music/player/albums/B09ZNTRL11" target="_blank">Amazon Music</a><br />
                <a href="https://www.deezer.com/us/album/316398587" target="_blank">Deezer</a><br />
                <a href="https://www.youtube.com/watch?v=zljd101vo68&list=OLAK5uy_mEwlP3emv3IvmnFngGU3Qm8sT3BPQlFfI" target="_blank">YouTube Music</a><br />
                <a href="https://www.qobuz.com/us-en/album/braden-victory-hands/nyw1xpzbbgmwb" target="_blank">Qobuz</a>
              </p>
            </div>
          }
        />
        <SingleColumnBlock
          centered
          text={
            <div>
              <h2>The TRIO YEARS:<br />ANDERSON - BERNSTEIN - BISHOP</h2>
            </div>
          }
        />
        <TextBlock
          textPadded
          textLeft={
            <div>
              <h3>BISHOP 12"</h3>
              <p>
                This release is the third and final in the original trio format. Featuring "Top Brass", "Dressed To The Tease", "Face These Facts", "This Kitchen", "Tonight He Stands", and "All In The Family".
              </p>
              <ScrollToButton
                id="top-brass-video"
                content="Watch Top Brass Video"
                style={{ marginBottom: "20px" }}
              />
              <h4>Download</h4>
              <p>
                Download in lossless or lossy archival format of your choice at <a href="https://victoryhands.bandcamp.com/" target="_blank">Bandcamp</a>.
              </p>
              <h4>Stream</h4>
              <p>
                <a href="https://open.spotify.com/album/0RxNTGur2Wy7SSlEFER4TO" target="_blank">Spotify</a><br />
                <a href="https://music.apple.com/us/album/bishop-ep/1626221640" target="_blank">Apple Music</a><br />
                <a href="https://tidal.com/browse/album/230933709" target="_blank">Tidal</a><br />
                <a href="https://www.amazon.com/music/player/albums/B0B2CTY88B" target="_blank">Amazon Music</a><br />
                <a href="https://www.deezer.com/us/album/322161927" target="_blank">Deezer</a><br />
                <a href="https://www.youtube.com/watch?v=TUjVNatp2Os&list=OLAK5uy_m1EwEglqJIL8EOM0474bIz9GTzSLEk4vQ" target="_blank">YouTube Music</a><br />
                <a href="https://www.qobuz.com/us-en/album/bishop-victory-hands/sd5bffpusk7ib" target="_blank">Qobuz</a>
              </p>
            </div>
          }
          textRight={
            <div>
              <img src="/images/BISHOP.jpg" alt="BISHOP 12-inch by Victory Hands" />
              <p className="small">Illustrations by Louie Crumbley.</p>
            </div>
          }
        />
        <TextBlock
          textPadded
          textLeft={
            <div>
              <img src="/images/BERNSTEIN.jpg" alt="BERNSTEIN 7-inch by Victory Hands" />
              <p className="small">Illustrations by Travis Medford.</p>
            </div>
          }
          textRight={
            <div>
              <h3>BERNSTEIN 7"</h3>
              <p>
                Featuring "Nixon Is My Copilot", "Lady Of The Lake", "September 23rd, 1952 (a.k.a. The Checkers Song)", and "Undressed To The Tease" (an early rendition of "Dressed To The Tease").
              </p>
              <h4>Download</h4>
              <p>
                Download in lossless or lossy archival format of your choice at <a href="https://victoryhands.bandcamp.com/" target="_blank">Bandcamp</a>.
              </p>
              <h4>Stream</h4>
              <p>
                Waiting on Congress to approve this measure.
              </p>
            </div>
          }
        />
        <TextBlock
          textPadded
          textLeft={
            <div>
              <h3>ANDERSON 10"</h3>
              <p>
                Featuring "The Guy We Can Kick" and "If They Give Him The Shaft"
              </p>
              <h4>Download</h4>
              <p>
                Download in lossless or lossy archival format of your choice at <a href="https://victoryhands.bandcamp.com/" target="_blank">Bandcamp</a>.
              </p>
              <h4>Stream</h4>
              <p>
                Waiting on Congress to approve this measure.
              </p>
            </div>
          }
          textRight={
            <div>
              <img src="/images/ANDERSON.jpg" alt="ANDERSON 10-inch by Victory Hands" />
              <p className="small">Illustrations by Doc Woglam.</p>
            </div>
          }
        />
      </PageWrapper>
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h2>The Cabinet.</h2>
            <p>
              In 2013 the Cabinet was first established with the appointments of Shawn Christopher as Secretary of Guitar, Kip Thomas as Secretary of Drums, and Jimmy Ether as Secretary of Bass. Mr. Ether and Mr. Christopher shared the Ambassador of Screaming position. And Jimmy Ether served as Director of Lyric Relations.
            </p>
            <p>
              In early 2019 the Cabinet was expanded with the appointment of Dain Johnson on bass guitar. Jimmy Ether moved to the newly created Secretary of Second Guitar and retained his other roles.
            </p>
          </div>
        }
        textRight={
          <div>
            <img src="/images/victory-hands-members.jpg" alt="Victory Hands Members" />
            <p className="small">Photo by Mike White of <a href="https://www.deadlydesigns.com/" target="_blank">Deadly Designs</a>.</p>
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <img src="/images/shawn-christopher-victory-hands.jpg" alt="Shawn Christopher of Victory Hands" />
            <p className="small">Photo by Mike White of <a href="https://www.deadlydesigns.com/" target="_blank">Deadly Designs</a>.</p>
          </div>
        }
        textRight={
          <div>
        <h3>Shawn Christopher</h3>
        <h4>Guitar/Vocals</h4>
        <p>
          Most Victory Hands songs are constructed around Mr. Christopher's off-kilter guitar riffs. He also is capable of one hell of a scream.<br/ >Absolutely.
        </p>
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h3>Kip Thomas</h3>
            <h4>Drums</h4>
            <p>
              Mr. Thomas brings his own off-kilter timing proclivities to the band, with a stated disdain for groups of even numbers unless absolutely necessary.<br/ >That's right.
            </p>
          </div>
        }
        textRight={
          <div>
            <img src="/images/kip-thomas-victory-hands.jpg" alt="Kip Thomas of Victory Hands" />
            <p className="small">Photo by Mike White of <a href="https://www.deadlydesigns.com/" target="_blank">Deadly Designs</a>.</p>
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <img src="/images/jimmy-ether-victory-hands.jpg" alt="Jimmy Ether of Victory Hands" />
            <p className="small">Photo by Mike White of <a href="https://www.deadlydesigns.com/" target="_blank">Deadly Designs</a>.</p>
          </div>
        }
        textRight={
          <div>
        <h3>Jimmy Ether</h3>
        <h4>Guitar/Vocals</h4>
        <p>
          Mr. Ether weaves alternately tuned harmonies and power-chord reinforcement as needed. He also takes on primary vocal duty and lyrical assignments.<br/ >Exactly.
        </p>
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h3>Dain Johnson</h3>
            <h4>Bass</h4>
            <p>
              Mr. Johnson brings two literal towers of detuned low-end resulting in a thunderous eruption that makes FEMA administrators rightfully nervous.<br/ >Excellent.
            </p>
          </div>
        }
        textRight={
          <div>
            <img src="/images/dain-johnson-victory-hands.jpg" alt="Dain Johnson of Victory Hands" />
            <p className="small">Photo by Mike White of <a href="https://www.deadlydesigns.com/" target="_blank">Deadly Designs</a>.</p>
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <img src="/images/victory-hands-live-events.jpg" alt="Victory Hands Live Events" />
            <p className="small">Photo by Mike White of <a href="https://www.deadlydesigns.com/" target="_blank">Deadly Designs</a>.</p>
          </div>
        }
        textRight={
          <div>
            <h2>Join us at our next rally.</h2>
            <p>
              To be kept up to date on rallies and public engagements follow our press briefings on <a href="https://www.facebook.com/victory.hands" target="_blank">Facebook</a> and <a href="https://www.instagram.com/_victoryhands/" target="_blank">Instagram</a>.
            </p>
            <ScrollToButton
              id="hope-live-video"
              content="Watch A Man Named Hope Live Video"
              style={{ marginBottom: "20px" }}
            />
          </div>
        }
      />
      <PageWrapper color="charcole">
        <div id="top-brass-video">
            <VideoPlayer youtubeId='m2tTBI8Ch1c' />
        </div>
        <div id="hope-live-video">
            <VideoPlayer youtubeId='d_RuSvMSEIg' />
        </div>
      </PageWrapper>
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h2>Correspond with staff.</h2>
            <RequestMoreInfo />
          </div>
        }
      />
    </PageLayout>
  )
}

export default IndexPage
